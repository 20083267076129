// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

// init swiper
var swiper = new Swiper (".swiper-container", {
    autoplay: 5000,
    speed: 150,
    loop: true,
    paginationClickable: true,
    nextButton: ".swiper-button-next",
    prevButton: ".swiper-button-prev",
});
